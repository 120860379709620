@use "breakpoints";
@use "colors";
@use "layout";

.personalization-banner {
  background-color: colors.$white;
  position: relative;
  z-index: 200;
}

.personalization-banner-inner {
  @include layout.module-content;

  padding-block: 5px;
  text-align: center;

  @media (width >= breakpoints.$md) {
    display: block;
  }
}

.personalization-copy {
  color: colors.$black;
  white-space: pre-line;

  & > * {
    margin-inline: 0.2em;
  }
}

.personalization-copy-link {
  border: 1px solid transparent;
  display: block;
  outline: none;
  text-decoration: underline;
  transition: all ease-in-out 300ms;

  &:focus,
  &:active {
    border: 1px solid colors.$black;
  }

  @media (width >= breakpoints.$md) {
    display: inline;
    padding-inline-start: 10px;

    &:hover {
      font-size: 19px;
      transition: all ease-in-out 300ms;
    }
  }
}

header + .personalization-banner {
  padding-block-start: var(--global-header-height);
}
